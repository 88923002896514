/**
 * Created by wangtong on 2022/2/12
 */
import {GroupData} from "@/models/group";

export interface UserShareRecord {
	id: number
	deleted: number // 是否删除
	user_id: number // 用户ID
	target_user_id: number // 被分享的用户ID
	day_time: number
	share_image_path: string
	share_image_width: number
	share_image_height: number
	share_image_size: number
	share_image_format: string
	share_image_md5: string
	suspend_expire_time: number // 封禁过期时间
	suspended: number
	title: string
	text: string
}

export function createUserShareRecord(): UserShareRecord {
	return {
		day_time: 0,
		deleted: 0,
		id: 0,
		share_image_format: "",
		share_image_height: 0,
		share_image_md5: "",
		share_image_path: "",
		share_image_size: 0,
		share_image_width: 0,
		suspend_expire_time: 0,
		suspended: 0,
		target_user_id: 0,
		text: "",
		title: "",
		user_id: 0
	}
}

export interface UserShareData {
	record: UserShareRecord
	user: UserData
}

export function createUserShareData(): UserShareData {
	return {
		record: createUserShareRecord(),
		user: createUserData()
	}
}

export interface GetUserShareDataRequest  {
	share_id: string
}


export interface UserInfo  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	uid: number // 用户UID，提供给客户端，防止客户端拿到用户的序列号
	sid: string // 用户散弹号
	nickname: string // 用户昵称
	gender: number // 用户性别 常量: 性别 UserGender
	sign: string // 用户个性签名
	suspend_expire_time: number // 封禁过期时间
	birthday: number // 生日，时间戳
	is_vip: number // 是否是vip
	friend_count: number // 好友数
	follow_count: number // 关注数
	fan_count: number // 粉丝数
	like_dynamic_count: number // 点赞动态数
	like_dynamic_comment_count: number // 点赞动态评论数
	create_dynamic_count: number // 创建动态数
	create_dynamic_comment_count: number // 创建动态评论数
	create_world_count: number // 创建世界数
	create_group_camp_count: number // 创建大本营数
	create_group_battle_count: number // 创建对线群数
	join_world_count: number // 加入世界数
	join_group_camp_count: number // 加入大本营数
	join_group_battle_count: number // 加入对线群数
	deny_msg: number // 是否不接收消息
	tag_count: number // 标签数
	suspended: number
	keyboard_points: number // 键盘积分
	level: number // 段位(青铜) 常量: 用户段位层次 UserDivisionLevel
	rank: number // 段位等级(1-5) 常量: 用户段位等级 UserDivisionRank
	star: number
	collect_dynamic_count: number // 收藏动态数
	collect_group_count: number // 收藏群组数
	report_dynamic_count: number // 举报动态数
	report_dynamic_comment_count: number // 举报动态评论数
	report_group_count: number // 举报群组数
	report_user_count: number // 举报的用户数量
	reported_count: number // 被举报次数
	battle_lose_count: number // 对线失败次数
	battle_win_count: number // 对线胜利次数
	is_camp_unlock: number // 大本营是否解锁
	block_user_count: number // 拉黑用户数
	battle_draw_count: number // 对线平局次数
	battle_total_count: number // 对线总次数
	battle_win_rate: number // 胜率
}

export interface UserAvatar  {
	id: number // ID
	path: string // 图片路径
	width: number // 图片宽度
	height: number // 图片高度
	size: number // 图片大小
	format: string // 图片格式
	md5: string // MD5
}

export interface UserBg  {
	id: number // ID
	path: string // 图片路径
	width: number // 图片宽度
	height: number // 图片高度
	size: number // 图片大小
	format: string // 图片格式
	md5: string // MD5
}

export interface UserData  {
	info: UserInfo
	avatar: UserAvatar
	bg?: UserBg
	data_option?: UserDataOption
	is_follow?: boolean // 是否关注
	is_fan?: boolean // 是否粉丝
	is_block?: boolean // 是否拉黑
	remark_name?: string // 备注名称
}

export interface UserTagRel  {
	user_id: number // 用户ID
	tag_id: number // 标签ID
	sort: number // 排序
}


export interface UserLoginData  {
	id: number // 用户ID
	access_token: string
}

export interface UserBlockUserRel  {
	src_id: number
	dst_id: number
	update_time: number // 更新时间
	deleted: number
}

export interface UserNotificationSetting  {
	deny_content_push: number // 内容推送
	deny_interaction_push: number // 互动消息
	deny_group_status_push: number // 群组状态提醒
	deny_msg: number // 是否拒绝消息推送
}

// UserFollowUserRel
// src_id 关注 dst_id
export interface UserFollowUserRel  {
	src_id: number // 粉丝用户ID
	dst_id: number // 被关注用户ID
	update_time: number // 更新时间
	deleted: number // 是否删除
}

export interface UserFollowUserData  {
	user: UserData
	rel: UserFollowUserRel
}

// UserDivision
// 用户的段位结构体，包含 level、rank、star、keyborad_point
export interface UserDivision  {
	level: number // 未知 青铜 白银 黄金 铂金 钻石 星耀 王者 常量: 用户段位层次 UserDivisionLevel
	rank: number // 青铜 白银 黄金 铂金 3个等级
	star: number // 青铜 3颗星
	keyborad_point?: number
}

export interface UserJoinGroupRel  {
	user_id: number
	group_id: number
	team_side: number // 加入的战队方 常量: 群战队方 GroupTeamSide
	role: number // 群成员角色
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除/是否加入
	is_player: number // 是否是玩家
	shut_up_expire_time: number // 禁言截止时间
	remark_name: string // 群内备注名称
	msg_accept_option: number // 消息接收选项 常量: 消息接收选项 MsgAcceptOption
	nickname: string // 群内昵称
	invite_id: number // 邀请ID
	invite_time: number // 邀请时间
	onlook_time: number // 开始围观时间
	play_time: number // 成为玩家/准备时间
	join_time: number // 加入时间
	is_pin: number // 是否置顶
}

export interface UserAchievementRule  {
	type: number // 成就类型 常量: 用户成就类型 UserAchievementType
	sort: number // 排序，越小的越靠前
	group: number // 成就分组 常量: 用户成就分组类型 UserAchievementGroupType
	rank_1_name: string // 段位1名称
	rank_1_threshold: string // 段位1阈值列表，用逗号分隔
	rank_1_list_desc_not_reach: string // 段位1列表规则描述（未达成）
	rank_1_list_desc_reach: string // 段位1列表规则描述（达成）
	rank_1_toast_desc_not_reach: string // 段位1大图展示的描述（未达成）
	rank_1_toast_desc_reach: string // 段位1大图展示的描述（达成）
	rank_1_group_message_desc: string // 段位1群消息描述
	rank_1_keyboard_points: number // 段位1键盘积分
	rank_2_name: string // 段位2名称
	rank_2_threshold: string // 段位2阈值列表，用逗号分隔
	rank_2_list_desc_not_reach: string // 段位2列表规则描述（未达成）
	rank_2_list_desc_reach: string
	rank_2_toast_desc_not_reach: string
	rank_2_toast_desc_reach: string
	rank_2_group_message_desc: string
	rank_2_keyboard_points: number // 段位2键盘积分
	rank_3_name: string // 段位3名称
	rank_3_threshold: string // 段位3阈值列表，用逗号分隔
	rank_3_list_desc_not_reach: string
	rank_3_list_desc_reach: string
	rank_3_toast_desc_not_reach: string
	rank_3_toast_desc_reach: string
	rank_3_group_message_desc: string
	rank_3_keyboard_points: number // 段位3键盘积分
	achieve_cycle_threshold: number // 达成程度循环阈值（达成程度需要模该值，最终阈值需要加上达成程度的除数）
}

export interface UserBattleRecord  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	user_id: number // 用户ID
	group_id: number // 群组ID
	team_side: number // 对线结算时加入的战队
	victory_state: number // 胜利状态 常量: 用户对线胜利状态 UserBattleVictoryState
	red_score: number // 他在红队的总得分
	red_message_score: number // 在红队发言得分
	red_invite_score: number // 拉人得分
	red_like_score: number // 获赞得分
	red_prop_score: number // 道具得分
	blue_score: number
	blue_message_score: number
	blue_invite_score: number
	blue_like_score: number
	blue_prop_score: number
	game_point_1: number // 获得的胜利节点1 常量: 对线群胜利节点类型 GroupBattleGamePointType
	game_point_2: number // 获得的胜利节点2 常量: 对线群胜利节点类型 GroupBattleGamePointType
	game_point_3: number // 获得的胜利节点3 常量: 对线群胜利节点类型 GroupBattleGamePointType
	score: number // 所在战队的比分
	message_score: number
	prop_score: number
	like_score: number
	invite_score: number
	role: number // 在对线群中的角色 常量: 对线群玩家角色 GroupBattlePlayerRole
	play_time: number // 加入成为玩家时间
	battle_duration: number // 对线时长
	red_message_count: number // 红方发言数
	blue_message_count: number // 蓝方发言数
	message_count: number // 加入战队的发言数
	total_message_count: number // 累计发言数
}

// UserBattleRecordData
// pivot: battle_finish_time
export interface UserBattleRecordData  {
	record?: UserBattleRecord
	group?: GroupData // 群组数据
	achievements?: UserAchievementData[]
	division?: UserDivisionRecord // 胜负段位记录
}

export interface UserRemarkUserRel  {
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	remark_name: string // 备注名称
	src_id: number
	dst_id: number
}

export interface UserDataOption  {
	avatar: boolean
	bg: boolean
	is_follow: number
	is_fan: number
	remark_name: number
	is_block: number // 是否拉黑
}

// UserLockGroupRel
// 仅在客户端使用
export interface UserLockGroupRel  {
	user_id?: number
	group_id?: number
	is_lock?: number
}

export interface UserPrivateChatMessageData  {
	record?: UserPrivateChatMessageRecord
}

export interface UserLocalInfo  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	uid: number // 用户UID，提供给客户端，防止客户端拿到用户的序列号
	sid: string // 用户散弹号
	nickname: string // 用户昵称
	gender: number // 用户性别 常量: 性别 UserGender
	sign: string // 用户个性签名
	suspend_expire_time: number // 封禁过期时间
	birthday: number // 生日，时间戳
	is_vip: number // 是否是vip
	friend_count: number // 好友数
	follow_count: number // 关注数
	fan_count: number // 粉丝数
	like_dynamic_count: number // 点赞动态数
	like_dynamic_comment_count: number // 点赞动态评论数
	create_dynamic_count: number // 创建动态数
	create_dynamic_comment_count: number // 创建动态评论数
	create_world_count: number // 创建世界数
	create_group_camp_count: number // 创建大本营数
	create_group_battle_count: number // 创建对线群数
	join_world_count: number // 加入世界数
	join_group_camp_count: number // 加入大本营数
	join_group_battle_count: number // 加入对线群数
	deny_msg: number // 是否不接收消息
	tag_count: number // 标签数
	suspended: number
	keyboard_points: number // 键盘积分
	level: number // 段位(青铜) 常量: 用户段位层次 UserDivisionLevel
	rank: number // 段位等级(1-5) 常量: 用户段位等级 UserDivisionRank
	star: number
	collect_dynamic_count: number // 收藏动态数
	collect_group_count: number // 收藏群组数
	report_dynamic_count: number // 举报动态数
	report_dynamic_comment_count: number // 举报动态评论数
	report_group_count: number // 举报群组数
	report_user_count: number // 举报的用户数量
	reported_count: number // 被举报次数
	battle_lose_count: number // 对线失败次数
	battle_win_count: number // 对线胜利次数
	is_camp_locked: number // 大本营是否锁住了
	remark_name: string // 备注名
	is_follow: boolean // 是否关注
	is_fan: boolean // 是否粉丝
	is_blacklist: boolean // 是否拉黑
}

export interface UserLikeGroupBattleMessageRel  {
	user_id?: number
	record_id?: number
	deleted?: number
}

export interface UserPrivateChatMessageRecord  {
	id: number // 客户端指定
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	msg_key: string // 消息唯一标识，用于撤回。长度不超过50个字符
	type: number
	sub_type: string
	sender_id: number
	sender_avatar: string
	sender_level: number
	sender_rank: number
	sender_star: number
	receiver_id: number
	send_time: number
	text: string
	image_path: string
	image_width: number
	image_height: number
	image_format: string
	image_size: number
	image_md5: string
	video_path: string
	video_width: number
	video_height: number
	video_format: string
	video_duration: number
	video_angle: number
	video_mirror: number
	video_md5: string
	video_snapshot_path: string
	video_snapshot_md5: string
	video_snapshot_format: string
	video_snapshot_size: number
	video_size: number
	video_vod_id: number
	video_vod_url: string
	video_vod_format: string
	video_vod_width: number
	video_vod_height: number
	video_vod_size: number
	audio_path: string
	audio_size: number
	audio_duration: number
	audio_md5: string
	audio_format: string
	status: number
	cloud_custom_data: string
	custom_data: string
	visible_type: number // 可见类型 常量: 消息可见类型 MessageChatVisibleType
	visible_target: number // 可见目标
	sender_is_vip: number // 发送方是否是VIP
	sender_show_name: string
	target_id: number // 目标ID
	target_user_id: string
	target_user_show_name: string // 目标用户展示名称
	target_user_avatar: string
	target_user_level: string
	target_user_rank: string
	target_user_star: string
	target_user_is_vip: string
	local_id: number // 客户端发送时的ID
	tip: string // 消息提示
	unique_key: string
	payload: string // 自定义消息负荷
	chat_key: string // 私信消息键
	seq: number
	target_unique_key: string
	message_id: string
}

export interface UserAchievementInfo  {
	create_time: number // 创建时间
	update_time: number // 更新时间
	user_id: number // 用户ID
	type: number // 成就类型
	achieve: number // 当前轮次实现程度
	rank_1_count: number // 段位1成就总达成次数
	rank_2_count: number // 段位2成就总达成次数
	rank_3_count: number // 段位3成就总达成次数
}

export interface UserAchievementData  {
	info?: UserAchievementInfo
	rule?: UserAchievementRule
}

// UserAchievementRecord
// 每达成一次成就，就新增一项（注意去重）
export interface UserAchievementRecord  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	type: number // 成就类型
	rank: number // 成就等级
	achieve: number // 实际达成值
	user_id: number // 用户ID
	group_id: number // 群组ID（在哪个群组达成的）
	pivot: number // 唯一校验支点
	threshold_index: number // 达成的阈值索引
	threshold: number // 达成的阈值
	is_read: number // 是否阅读了
	read_time: number // 阅读时间
}

export interface UserDivisionRecord  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	user_id: number // 用户ID
	source: number // 常量: 用户键盘积分来源 UserKeyboardPointsSource
	keyboard_points_incr: number // 增加的键盘积分
	level_incr: number // 增加的等级
	rank_incr: number
	star_incr: number
	keyboard_points_reach: number
	rank_reach: number
	level_reach: number
	star_reach: number
	group_id: number // 群组ID
	reason: string // 添加原因
	create_manager_id: number // 添加管理员ID
	achievement_record_id: number // 成就记录ID
	unique_key: string
}

export interface UserDivisionInfo  {
	user_id: number
	keyboard_points: number
	level: number
	rank: number
	star: number
	is_camp_unlock: number
}

export interface UserAchievementRecordData  {
	achievement?: UserAchievementRecord
	division?: UserDivisionRecord
	rule?: UserAchievementRule
}

export interface UserApplyJoinGroupCampStatusData  {
	type: number // 常量: 用户最近申请入群审核状态类型 UserApplyJoinGroupStatusType
	can_apply_time: number // 什么时候可以再次申请
}

export interface UserPropInfo  {
	user_id: number
	type: number
	quantity: number
}

export interface UserPropData  {
	info?: UserPropInfo
	rule?: UserPropRule
}

export interface UserPropRule  {
	type: number // 常量: 道具类型 PropType
	use_up_title: string // 用尽弹窗标题
	use_up_desc: string // 用尽弹窗描述
	question_title: string // 问题弹窗标题
	question_desc: string // 问题弹窗描述
	prop_get_message_title: string // 获得该道具消息标题
	prop_get_message_desc: string // 获得该道具消息描述
}

export interface UserPrivateChatOutlineData  {
	user_id: number // 用户ID
	unread_count: number // 未读消息计数
	is_mention_me: number // 是否at了我
}

// UserRegisterInfo
// 注册的时候传递，后面只读不取
export interface UserRegisterInfo  {
	id: number // 用户ID
	create_time: number // 创建时间
	channel: string // 渠道
	version: number // 注册版本
	version_name: string // 注册版本名称
	platform: number // 注册平台 常量: 平台 Platform
	oaid: string // 匿名设备标识符
	android_sdk: number
	ip: string // 注册IP
	city: string // 注册城市
	country: string // 国家
	province: string // 省份
	device_brand: string // 品牌名称
	device_model: string // 设备型号
	install_version: number // 安装版本号
	install_version_name: string // 安装版本名称
	install_time: number // 安装时间
	os: string // 操作系统
	os_version: string // 操作系统版本
	screen_height: number // 屏幕高度
	screen_width: number // 屏幕宽度
	umid: string // 友盟设备ID
	idfa: string // IOS广告标识符
	mac: string // MAC地址
	oid: string // OpenUDID
	network_access: string // 入网类型
	idfv: string // Vendor标识符
	imei: string // 国际移动设备识别码
	is_robot: number // 是否是马甲号
	is_night_mode: number // 是否是暗黑模式
	device_hardware: string // 硬件名称
	device_product: string // 整个产品的名称
	device_name: string // 设备参数
	device_display: string // 显示屏参数
	device_manufacturer: string // 硬件制造商
	android_id: string // 安卓ID
	user_agent: string
}

export interface UserTpnsRegisterLog  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	user_id: number // 用户ID
	reg_id: string
	tpns_token: string
	user_agent: string
	channel: string
	manufacturer: string
	brand: string
	model: string
	oaid: string
	platform: number
	sdk_int: number
	os_version: number
	version: number
	version_name: string
	ip: string
	business_id: number
}



export function createUserInfo(): UserInfo {
	return {
		battle_draw_count: 0,
		battle_lose_count: 0,
		battle_total_count: 0,
		battle_win_count: 0,
		battle_win_rate: 0,
		birthday: 0,
		block_user_count: 0,
		collect_dynamic_count: 0,
		collect_group_count: 0,
		create_dynamic_comment_count: 0,
		create_dynamic_count: 0,
		create_group_battle_count: 0,
		create_group_camp_count: 0,
		create_time: 0,
		create_world_count: 0,
		delete_time: 0,
		deleted: 0,
		deny_msg: 0,
		fan_count: 0,
		follow_count: 0,
		friend_count: 0,
		gender: 0,
		id: 0,
		is_camp_unlock: 0,
		is_vip: 0,
		join_group_battle_count: 0,
		join_group_camp_count: 0,
		join_world_count: 0,
		keyboard_points: 0,
		level: 0,
		like_dynamic_comment_count: 0,
		like_dynamic_count: 0,
		nickname: "",
		rank: 0,
		report_dynamic_comment_count: 0,
		report_dynamic_count: 0,
		report_group_count: 0,
		report_user_count: 0,
		reported_count: 0,
		sid: "",
		sign: "",
		star: 0,
		suspend_expire_time: 0,
		suspended: 0,
		tag_count: 0,
		uid: 0,
		update_time: 0
	}
}

export  function createUserData(): UserData {
	return  {
		avatar: {
			format: "", height: 0, id: 0, md5: "", path: "", size: 0, width: 0
		},
		bg: {
			format: "", height: 0, id: 0, md5: "", path: "", size: 0, width: 0
		},
		data_option: {
			avatar: false, bg: false, is_block: 0, is_fan: 0, is_follow: 0, remark_name: 0
		},
		info: createUserInfo(),
		is_block: false,
		is_fan: false,
		is_follow: false,
		remark_name: ""
	}
}
export {};
