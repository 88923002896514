/**
 * Created by wangtong on 2021/6/26
 */
import {ImageStyle} from "@/constants";

function buildOssUrl(path: string, style?: ImageStyle): string {
	console.log(`buildOssUrl path=${path} style=${style} base=${process.env.VUE_APP_CDN_OSS_BASE}`)
	if (!path) {
		return ''
	}
	let url = path
	if (path.startsWith('http')) {
		url = path
	} else if (path.startsWith('data')) {
		return path
	} else {
		url = `${process.env.VUE_APP_CDN_OSS_BASE}/${path}`
	}
	
	if (style) {
		url += `?x-oss-process=style/${style}`
	}
	console.log(`buildOssUrl url=${url} style=${style}`)
	return url
}

function extractOssPath(url: string): string {
	if (url.startsWith('data')) {
		return url
	} else if (url.startsWith('http')) {
		let prefixReg = new RegExp(`.*${process.env.VUE_APP_OSS_URL}/`)
		let suffixReg = new RegExp('\\?x-oss-process=style.*')
		return url.replace(prefixReg, "").replace(suffixReg, "")
	} else {
		return url
	}
}

export {
	buildOssUrl,
	extractOssPath
};
