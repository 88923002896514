import {UserDivisionLevelCons} from "@/constants";

/**
 * Created by wangtong on 2022/6/12
 */

const rankFrameBronze = require("@/assets/images/rank/rank_avatar_frame_bronze.png")
const rankFrameChallenger = require("@/assets/images/rank/rank_avatar_frame_challenger.png")
const rankFrameDiamond = require("@/assets/images/rank/rank_avatar_frame_diamond.png")
const rankFrameGold = require("@/assets/images/rank/rank_avatar_frame_gold.png")
const rankFramePlatinum = require("@/assets/images/rank/rank_avatar_frame_platinum.png")
const rankFrameSilver = require("@/assets/images/rank/rank_avatar_frame_silver.png")
const rankFrameStarlight = require("@/assets/images/rank/rank_avatar_frame_starlight.png")

const rankLogoBronze = require("@/assets/images/rank/rank_logo_bronze.png")
const rankLogoChallenger = require("@/assets/images/rank/rank_logo_challenger.png")
const rankLogoDiamond = require("@/assets/images/rank/rank_logo_diamond.png")
const rankLogoGold = require("@/assets/images/rank/rank_logo_gold.png")
const rankLogoPlatinum = require("@/assets/images/rank/rank_logo_platinum.png")
const rankLogoSilver = require("@/assets/images/rank/rank_logo_silver.png")
const rankLogoStarlight = require("@/assets/images/rank/rank_logo_starlight.png")



export function userDivisionFrameImage(level: number): string {
	switch (level) {
		case UserDivisionLevelCons.Bronze.value:
			return rankFrameBronze
		case UserDivisionLevelCons.Challenger.value:
			return rankFrameChallenger
		case UserDivisionLevelCons.Diamond.value:
			return rankFrameDiamond
		case UserDivisionLevelCons.Gold.value:
			return rankFrameGold
		case UserDivisionLevelCons.Platinum.value:
			return rankFramePlatinum
		case UserDivisionLevelCons.Silver.value:
			return rankFrameSilver
		case UserDivisionLevelCons.Starlight.value:
			return rankFrameStarlight
		default:
			return rankFrameBronze
	}
}


export function userDivisionLogoImage(level: number): string {
	switch (level) {
		case UserDivisionLevelCons.Bronze.value:
			return rankLogoBronze
		case UserDivisionLevelCons.Challenger.value:
			return rankLogoChallenger
		case UserDivisionLevelCons.Diamond.value:
			return rankLogoDiamond
		case UserDivisionLevelCons.Gold.value:
			return rankLogoGold
		case UserDivisionLevelCons.Platinum.value:
			return rankLogoPlatinum
		case UserDivisionLevelCons.Silver.value:
			return rankLogoSilver
		case UserDivisionLevelCons.Starlight.value:
			return rankLogoStarlight
		default:
			return rankLogoBronze
	}
}

export function userDivisionBackgroundGradient(level: number): string {
	switch (level) {
		case UserDivisionLevelCons.Bronze.value:
			return 'linear-gradient(to bottom right, #22543B80, #25752B80)'
		case UserDivisionLevelCons.Challenger.value:
			return 'linear-gradient(to bottom right, #DA000080, #FEAA1680)'
		case UserDivisionLevelCons.Diamond.value:
			return 'linear-gradient(to bottom right, #3A5FC080, #033ACD80)'
		case UserDivisionLevelCons.Gold.value:
			return  'linear-gradient(to bottom right, #ECD71F80, #CB6D0780)'
		case UserDivisionLevelCons.Platinum.value:
			return  'linear-gradient(to bottom right, #C5A9CE80, #4E4B6880)'
		case UserDivisionLevelCons.Silver.value:
			return 'linear-gradient(to bottom right, #89A1AD80, #435D7380)'
		case UserDivisionLevelCons.Starlight.value:
			return 'linear-gradient(to bottom right, #3A5FC080, #033ACD80)'
		default:
			return 'linear-gradient(to bottom right, #22543B80, #25752B80)'
	}
}

export function userDivisionBackgroundLightGradient(level: number): string {
	switch (level) {
		case UserDivisionLevelCons.Bronze.value:
			return 'linear-gradient(to bottom right, #8AF688, #3FA549)'
		case UserDivisionLevelCons.Challenger.value:
			return 'linear-gradient(to bottom right, #FFF02E, #F6A008)'
		case UserDivisionLevelCons.Diamond.value:
			return 'linear-gradient(to bottom right, #BEDBF2, #7FDDFE)'
		case UserDivisionLevelCons.Gold.value:
			return  'linear-gradient(to bottom right, #FFE768, #ECBF35)'
		case UserDivisionLevelCons.Platinum.value:
			return  'linear-gradient(to bottom right, #E2DFF4, #BCC0D8)'
		case UserDivisionLevelCons.Silver.value:
			return 'linear-gradient(to bottom right, #D5E3EF, #BDD0E0)'
		case UserDivisionLevelCons.Starlight.value:
			return 'linear-gradient(to bottom right, #95F2F5, #E195EF)'
		default:
			return 'linear-gradient(to bottom right, #8AF688, #3FA549)'
	}
}

export const icVS = require("@/assets/images/ic_vs.png")


export {};
