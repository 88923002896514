














/**
 * Created by wangtong on 2022/2/4
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {buildOssUrl} from "@/utils/image";
import {Browser, Convert} from "@sandan/febasic";
import {OfficialWebsiteQuery} from "@/models";

@Component({})
export default class ShareCard extends Vue {
    @Prop() image!: string
    @Prop() title!: string
    @Prop() text!: string
    @Prop() copyText!: string


    get shareImage(): string {
        if (this.image) {
            return buildOssUrl(this.image)
        } else {
            return ""
        }
    }

    get shareText(): string {
        return this.text || '散弹：00后的社交元宇宙'
    }

    onCopy() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }
    }
}
